import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { LearnMoreButton } from '../common/LearnMoreButton';

const MetadataMadeSimple = () => {
  return (
    <div className="metadata-made-simple">
      <div className="container">
        <div className="row ptb--60">
          <div className="col-lg-12">
            <ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={50}>
              <h2>We make working with metadata simple</h2>
              <p>
                Noctil software is an extension of your existing investment to manage your
                metadata accurately. Improve business agility and interoperability with
                our no-code system. <br />
                It supports in-house data models, is user-friendly, and cuts costs by 80%,
                making it affordable and saving quite a bit on operational costs.
              </p>
            </ScrollAnimation>
          </div>
          <div className="grid-center-cols--3 mt--40 pb--20">
            <Card
              imgPath={'assets/images/redesignV2/6.png'}
              text={<h4>Integrate our no-code platform easily into your system</h4>}
              onLearnMoreClick={() => {
                window.open(
                  'https://blogs.noctil.com/best-metadata-management-tool/',
                  '_blank'
                );
              }}
            />
            <Card
              imgPath={'assets/images/redesignV2/7.png'}
              text={<h4>Change the way you work with your catalogs</h4>}
              onLearnMoreClick={() => {
                window.open(
                  'https://blogs.noctil.com/royalty-software-for-publishers/',
                  '_blank'
                );
              }}
            />
            <Card
              imgPath={'assets/images/redesignV2/8.png'}
              text={<h4>Reduce costs to maximize revenue</h4>}
              onLearnMoreClick={() => {
                window.open(
                  'https://blogs.noctil.com/metadata-integration-and-quality/',
                  '_blank'
                );
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MetadataMadeSimple;

const Card = ({ imgPath, text, onLearnMoreClick }) => {
  return (
    <div className="card-wrapper">
      <div className="inner-wrap">
        <img src={imgPath} alt="metadata" width={'100px'} height={'100px'} />
        <div>
          {text}
          <LearnMoreButton onClick={onLearnMoreClick} />
        </div>
      </div>
    </div>
  );
};
