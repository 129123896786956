import React from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

const GetStartedButton = ({ text = 'Get Started' }) => {
  const history = useHistory();

  return (
    <button
      onClick={() => {
        history.push('/get-started');
      }}
      className="custom-btn"
    >
      {text}
    </button>
  );
};

export default GetStartedButton;
