import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { LearnMoreButton } from '../common/LearnMoreButton';
import { useHistory } from 'react-router-dom';

const DiscoverPartnership = () => {
  const history = useHistory();
  return (
    <div className="subscribeNoctil discover-partnership">
      <div className="container">
        <div className="row ptb--30">
          <div className="col-lg-12">
            <ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={50}>
              <h2 className="text-center font--54">
                Discover Partnership Opportunities With Noctil
              </h2>
            </ScrollAnimation>
          </div>
          <div className="grid-center-cols--3--gap--100 mt--40 w-100 plr--120">
            <Card
              imgPath={'assets/images/redesignV2/52.png'}
              text={'Music Publishers'}
              onLearnMoreClick={() => {
                history.push('/music-publisher-and-admins');
              }}
            />
            <Card
              imgPath={'assets/images/redesignV2/53.png'}
              text={'Record Labels'}
              onLearnMoreClick={() => {
                history.push('/record-labels');
              }}
            />
            <Card
              imgPath={'assets/images/redesignV2/54.png'}
              text={'Music Rights Societies'}
              onLearnMoreClick={() => {
                history.push('/music-rights-societies');
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DiscoverPartnership;

const Card = ({ imgPath, text, onLearnMoreClick }) => {
  return (
    <div className="card-wrapper">
      <div className="flex-col-start-gap--1">
        <img
          src={imgPath}
          alt="metadata"
          height={'250px'}
          style={{ objectFit: 'cover', borderRadius: '28px', width: '100%' }}
        />
        <h4>{text}</h4>
        <LearnMoreButton
          style={{
            border: '1px #1b1b1b solid',
          }}
          onClick={onLearnMoreClick}
        />
      </div>
    </div>
  );
};
