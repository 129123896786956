import React, { useEffect, useRef } from 'react';

const useInfiniteMarquee = (ref1, ref2) => {
  useEffect(() => {
    const marquee1 = ref1.current;
    const marquee2 = ref2.current;
    const width = marquee1.offsetWidth;

    marquee2.style.transform = `translateX(${width}px)`;

    const handleAnimation = () => {
      marquee1.style.transform = 'translateX(0)';
      marquee2.style.transform = `translateX(${width}px)`;
    };

    marquee1.addEventListener('animationiteration', handleAnimation);
    marquee2.addEventListener('animationiteration', handleAnimation);

    return () => {
      marquee1.removeEventListener('animationiteration', handleAnimation);
      marquee2.removeEventListener('animationiteration', handleAnimation);
    };
  }, [ref1, ref2]);
};

const InfiniteLogoMarquee = ({ images }) => {
  const marqueeRef1 = useRef(null);
  const marqueeRef2 = useRef(null);

  useInfiniteMarquee(marqueeRef1, marqueeRef2);

  return (
    <div className="logos-marquee-wrapper">
      <div className="logos-marquee-slide" ref={marqueeRef1}>
        {images.map((image, index) => (
          <img key={index} src={image.src} alt={image.alt} />
        ))}
      </div>
      <div
        className="logos-marquee-slide repeated-logos-marquee-slide"
        ref={marqueeRef2}
        style={{ marginLeft: '160px' }}
      >
        {images.map((image, index) => (
          <img
            style={{ marginLeft: index === 0 ? '50px' : '' }}
            key={index}
            src={image.src}
            alt={image.alt}
          />
        ))}
      </div>
    </div>
  );
};

export default InfiniteLogoMarquee;
