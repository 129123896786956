import React from 'react';
import { useHistory } from 'react-router-dom';

const SuperChargeYourBusiness = ({ title, subTitle, imgPath }) => {
  const history = useHistory();

  return (
    <div style={{ background: '#FCF3FF' }} className="ptb--40">
      <div className="align-items-center justify-content-center">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h2 className="title">{title} </h2>
              <p className="mb--40">{subTitle}</p>
              <div className="mb--40">
                <img
                  src={imgPath}
                  alt="Noctil-Improve royalty distribution accuracy up to 98%"
                />
              </div>
              <div className="flex-center">
                <button
                  onClick={() => {
                    history.push('/get-use-cases');
                  }}
                  className="custom-btn"
                >
                  Read Our Client's Story
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuperChargeYourBusiness;
