import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import GetStartedButton from '../GetStartedButton';

const NoctilForRecordLabels = ({ mainTitle, subTitle, paragraph, imgPath }) => {
  return (
    <div className="subscribeNoctil bg-gradient mt--85 noctil-for-record-labels">
      <div className="container">
        <div className="row">
          <div className="col-md-7">
            <ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={50}>
              <h3>{subTitle}</h3>
              <h2>{mainTitle}</h2>
              <p>{paragraph}</p>
              <div className="mt--80">
                <h5>Get 30 days free trial</h5>
                <GetStartedButton />
              </div>
            </ScrollAnimation>
          </div>
          <div className="col-md-5">
            <ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={50}>
              <img src={imgPath} alt="Take the Best Step Forward with Noctil!" />
            </ScrollAnimation>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoctilForRecordLabels;
