import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import PageScrollTop from './component/PageScrollTop';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import MainLayout from './component/layout/MainLayout/MainLayout';

import Home from './pages/Home';
import About from './pages/About';
import Solutions from './pages/Solutions';
import SolutionDetails from './pages/SolutionDetails';
import Careers from './pages/Careers';
import Resources from './pages/Resource';
import Blogs from './pages/BlogList';
import Faqs from './pages/Faqs';
import PrivacyPolicy from './pages/PrivacyPolicy';
import PageNotFound from './pages/error404';
import AipaSuccessStory from './pages/blogs/AipaSuccessStory';
import MusicRepertoireDatabse from './pages/blogs/MusicRepertoireDatabse';
import BlockchainInMusic from './pages/blogs/BlockchainInMusic';
import RentBuyBuild from './pages/blogs/RentBuyBuild';
import RecordLabels from './pages/RecordLabels';
import MusicPublishersAndAdmins from './pages/MusicPublishersAndAdmins';
import MusicRightsSocieties from './pages/MusicRightsSocieties';
import GoogleTagManager from './component/GoogleTagManager';
import GetStarted from './pages/GetStarted';
import useStickyHeader from './hooks/useStickHeader';
const Root = () => {
  useStickyHeader();

  return (
    <BrowserRouter basename={'/'}>
      <PageScrollTop>
        <MainLayout>
          <GoogleTagManager gtmId="GTM-5RVJP25L" />
          <Switch>
            <Route exact path={`${process.env.PUBLIC_URL}/`} component={Home} />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/get-30-day-free-trial`}
              component={Home}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/get-started`}
              component={GetStarted}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/get-use-cases`}
              component={GetStarted}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/privacy-policy`}
              component={PrivacyPolicy}
            />
            <Route exact path={`${process.env.PUBLIC_URL}/about`} component={About} />
            {/* <Route
                exact
                path={`${process.env.PUBLIC_URL}/solutions`}
                component={Solutions}
              /> */}
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/solutions/:id`}
              component={SolutionDetails}
            />

            <Route
              exact
              path={`${process.env.PUBLIC_URL}/resources`}
              component={Resources}
            />
            <Route exact path={`${process.env.PUBLIC_URL}/blogs`} component={Blogs} />
            <Route exact path={`${process.env.PUBLIC_URL}/careers`} component={Careers} />
            <Route exact path={`${process.env.PUBLIC_URL}/faqs`} component={Faqs} />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/privacy-policy`}
              component={PrivacyPolicy}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/aipa-success-story`}
              component={AipaSuccessStory}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/music-repertoire-databse`}
              component={MusicRepertoireDatabse}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/blockchain-in-music`}
              component={BlockchainInMusic}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/rent-buy-build`}
              component={RentBuyBuild}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/record-labels`}
              component={RecordLabels}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/music-publisher-and-admins`}
              component={MusicPublishersAndAdmins}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/metadata-management-software-for-music-publishers-and-record-labels`}
              component={MusicPublishersAndAdmins}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/music-rights-societies`}
              component={MusicRightsSocieties}
            />
            <Route exact path="*" component={PageNotFound} />
          </Switch>
        </MainLayout>
      </PageScrollTop>
    </BrowserRouter>
  );
};

ReactDOM.render(<Root />, document.getElementById('root'));
serviceWorker.register();
