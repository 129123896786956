import React from 'react';
import NoctilForRecordLabels from '../component/noctilForRecordLabels/NoctilForRecordLabels';
import ScheduleADemoSection from '../component/scheduleADemoSection/ScheduleADemoSection';
import Retain100PercentOfRights from '../component/retain100PercentOfRights/Retain100PercentOfRights';
import CollaborateWithGlobal from '../component/collaborateWithGlobal/CollaborateWithGlobal';
import SuperChargeYourBusiness from '../component/superChargeYourBusiness/SuperChargeYourBusiness';

const RecordLabels = () => {
  return (
    <div>
      <NoctilForRecordLabels
        subTitle="Noctil for Record Labels"
        mainTitle="Accelerate registration of music catalogue with global societies"
        paragraph="Noctil provides accuracy, and efficiency in processing metadata, without any share in royalty fees."
        imgPath="/assets/images/redesignV2/31.png"
      />
      <Retain100PercentOfRights />
      <CollaborateWithGlobal
        title="Connect with different agencies and societies"
        subTitle="Integrate Noctil and expand your network"
      />
      <SuperChargeYourBusiness
        title="Supercharge your business with Noctil"
        subTitle="With lower implementation time and affordable costs, our platform is a
                no-code, supporting your data models and making your data instantly
                compatible for collaboration. Boost agility, speed up delivery, and
                maximize your revenue"
        imgPath="/assets/images/home-carousel/home-carousel-1.svg"
      />
      <ScheduleADemoSection />
    </div>
  );
};

export default RecordLabels;
